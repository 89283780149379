.font-size-dropdown {
    display: inline-block;
}

.font-size-menu {
    display: inline-block;
    position: absolute;
    top: 3px;
    background: #FFFFFF;
    box-shadow: 0 0 2px;
    max-height: 150px;
    overflow-y: scroll;
}

.font-size-menu::-webkit-scrollbar {
    display: none
}

.font-size-option {
    padding: 3px;
}

.font-size-option:hover {
    background: #F0F0F0;
    cursor: pointer;
}

.text-editor{
  border : 1px solid black;
}