html {
  font-family: Helvetica, -apple-system, "Segoe UI", "Helvetica Neue", sans-serif;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

a.non-decorate {
  color: inherit !important;
  text-decoration: inherit !important;
}
a.non-decorate :hover {
  color: inherit !important;
  text-decoration: inherit !important;
}

.text-end {
  text-align: right;
}

#root {
  height: 100%;
}

/* Material subcategory toggle 색상 */
.MuiToggleButton-root.Mui-selected {
  background-color: #c51f1f1f !important;
}

/* 제품 목록 */
.productTable {
  width: 100%;
  margin: auto;
}

.productTable thead {
  border-bottom: 1px solid #a24642;
  font-weight: bold;
  font-size: 0.8rem;
}

.productTable thead td,
.productTable tbody td {
  font-size: 1rem;
  padding: 5px;
  text-align: center;
}

/* 공지사항 */

.notice .list-pinned {
  background-color: rgb(255, 243, 241);
}
.notice .list-pinned > .link {
  color: #ab003c;
  font-weight: bold;
}
.notice thead {
  background-color: #efefef;
}
.notice thead,
.notice tbody {
  text-align: center;
}

.notice thead .no,
.notice thead .views {
  width: 10%;
  min-width: 60px;
}
.notice thead .date,
.notice thead .writer {
  min-width: 123px;
  width: 10%;
}

.notice tbody .link:hover {
  cursor: pointer;
  text-decoration: underline #b0b3c5;
  color: #b0b3c5;
}
.notice .link {
  text-align: left;
}

i {
  color: #9e9e9e;
}

.large input[type="text"] {
  font-size: 1.2rem;
}

/* 장바구니 */
.cartTable {
  width: 100%;
  margin: auto;
}

.cartTable thead,
.cartTable tfoot {
  background-color: #efefef;
  font-weight: bold;
  font-size: 1.09rem;
}

.cartTable tbody tr:not(:last-child) {
  border-bottom: 0.5px solid #757575;
}

.cartTable thead td,
.cartTable tbody td,
.cartTable tfoot td {
  padding: 10px;
}
/* Warranty */
.warrantyTable {
  width: 100%;
  margin: auto;
}

.warrantyTable thead {
  background-color: #efefef;
  font-weight: bold;
  font-size: 1.09rem;
}

.warrantyTable tbody tr:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}

.warrantyTable thead td,
.warrantyTable tbody td {
  padding: 10px;
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(33, 33, 33, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
  font-size: large;
}
.navbar {
  background-color: white;
  max-width: 1280px;
  margin: 0 auto;
  border-bottom: 0.5px solid gainsboro;
}

.nav-item.dropdown > a::after {
  display: none;
}

/* for user card */
.usercard .dropdown-menu {
  min-width: 200px;
  margin: 0px;
  padding: 0px;
}

.usercard .dropdown-menu button {
  height: 100%;
}

/* for user card end */

/* requestOrder */
.requestorder {
  padding: 10px 10px 10px 5px;
  margin-bottom: 10px;
}

.requestorder.company {
  border: 5px solid #e8f4fd;
}

.requestorder.company:hover {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.requestorder > div {
  line-height: 1rem;
}

.requestorder div > * {
  font-size: 0.8rem;
}

.requestorder div > p {
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.5px;
}

.commentModal.box {
  padding: 10px 10px 10px 5px;
  margin-bottom: 10px;
  border: 5px solid #e8f4fd;
}

.commentModal.manager:hover {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.commentModal.title {
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.5px;
}

.commentModal p {
  letter-spacing: 0.5px;
}

/* multiline - line-height */
.MuiInputBase-root > textarea {
  line-height: 130%;
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index: 1;
}
.scroll-to-top img {
  width: 50px;
  height: 50px;
}

@media (max-width: 960px) {
  .scroll-to-top img {
    width: 30px;
    height: 30px;
  }
}
